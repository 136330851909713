import { ReactElement } from 'react'
import image1 from './assets/Slide1.JPG'
import image10 from './assets/Slide10.JPG'
import image2 from './assets/Slide2.JPG'
import image3 from './assets/Slide3.JPG'
import image4 from './assets/Slide4.JPG'
import image5 from './assets/Slide5.JPG'
import image6 from './assets/Slide6.JPG'
import image7 from './assets/Slide7.JPG'
import image8 from './assets/Slide8.JPG'
import image9 from './assets/Slide9.JPG'
import * as S from './style'

const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10]

export default function RecognitionWaveThree (): ReactElement {
  return <S.Container>
      {images.map((image) => <img src={image} alt="" />)}
    </S.Container>
}
